export const custom = {
  /**
  * Idiomas disponibles.
  */
  availableLangs: ['es','en','pt','cat'],
  /**
   * Idioma por defecto
   */
  defaultLang: 'es',

  /**
   * Versión de la API: v2|v3
   */
  apiVersion: 'v3',

  /**
   * API key de conexión a la api definida en cada entorno
   * (debe ser el mismo en todos los servicios)
   */
  apiKey: '7258fbae-0f4a-4817-9354-be10cd81bf87',

  /**
   * URL de la app
   *
   * subdominio.dominio
   */
  appUrlDomain: 'appdemo.alares',

  /**
  * Nombre de la app
  */
  appName: 'App Demo para V3',

  /**
  * Código de analytics
  * (Activado en pro, desactivado en pre y local)
  */
  analyticsId: 'G-XXXXXXXXXXX',

  /** true o false. Define si es necesario hacer login para acceder a los contenidos */
  protected: true,

  /** Registro habilitado o deshabilitado */
  registration: true,

  /** true o false. Define si los formularios son para conciliación o no */
  conciliation: true
};
